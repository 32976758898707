import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { createUseStyles, ThemeProvider } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { actions as TrackingActions } from '../../redux/tracking/redux'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as PageSelectors } from '../../Wrappers/Pages/Page/redux'
import { themeNames } from '../../theme'
import { Router } from '../../routes'
import ErrorView from '../../pages/Error'
import Tracker from '../Tracker'
import Seo from '../../components/Seo'
import app from '../../configuration/app'
import withMemo from '../../decorators/withMemo'
import i18next from '../../i18n'
import LoadingBar from '../../components/LoadingBar'
import { tcPagenameTransformer } from '../../transformers'

import styles from './styles'


const useStyles = createUseStyles(styles)

let prevPagename = ''

const AppGate = (props) => {
  // eslint-disable-next-line react/prop-types
  const { Component, pageContext, ...pageProps } = props

  const alternates = useSelector(PageSelectors.alternates)
  const preview = useSelector(AppSelectors.preview)
  const seo = useSelector(AppSelectors.seo)
  const jsonld = useSelector(AppSelectors.jsonld)

  const isError = pageProps.error
  const isErrorComp = pageProps.errorComponent

  const classes = useStyles(props)
  const dispatch = useDispatch()

  const { i18n } = useTranslation()
  const variant = useSelector(PageSelectors.variant)

  useEffect(() => {
    // on load
    document.querySelector('html').setAttribute('lang', i18n?.language?.substr(0, 2)?.toLowerCase())
    // on language change
    i18next.on('languageChanged', () => {
      document.querySelector('html').setAttribute('lang', i18n?.language?.substr(0, 2)?.toLowerCase())
    })
    // cleanup
    return () => {
      i18next.off('languageChanged')
    }
  }, [i18n])

  const changeTcVars = useCallback(() => {
    if (window?.tc_vars && seo) {
      const metas = seo?.metas ?? []
      const pagename = tcPagenameTransformer(metas)

      if (pagename) {
        window.tc_vars.pagename = pagename
        // https://community.commandersact.com/tagcommander/tips-and-tricks/best-practices/common-trigger-strategies#page-view-trigger
        if (typeof tC !== 'undefined') {
          if (prevPagename !== pagename) {
            prevPagename = pagename
            // eslint-disable-next-line no-undef
            // tC.container.reload({ events: { pageview: [{}, {}] } })
          }
        }
      }
    }
  }, [seo])


  useEffect(() => {
    changeTcVars()
    Router.onRouteChangeComplete = () => {
      dispatch(TrackingActions.trackPage())
      changeTcVars()
    }
  }, [changeTcVars, dispatch])

  return (
    <div className={classes.wrapper}>
      {seo && seo.metas && (
        <Seo
          {...seo}
          alternates={alternates}
          base={preview === true ? app.APP_PREVIEW_URL : app.APP_URL}
          jsonld={jsonld || {}}
        />
      )}
      <LoadingBar variant={variant} />
      <Tracker />

      {isError && !isErrorComp && (
      <ErrorView
        pageContext={pageContext}
        {...pageProps}
      />
      )}

      {Component && (
        <>
          <ThemeProvider theme={{ APP_THEME: themeNames.DEFAULT }}>
            <Component
              pageContext={pageContext}
              {...pageProps}
            />
          </ThemeProvider>
        </>
      )}
    </div>
  )
}

AppGate.propTypes = {
  pageProps: PropTypes.shape({
    error: PropTypes.number,
    errorComponent: PropTypes.any,
  }),
}

AppGate.defaultProps = {
  pageProps: {},
}

export default withMemo()(AppGate)
